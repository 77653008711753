.sec-2 {
  width: 100%;
  height: fit-content;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  gap: 20px;
}
.sec-2 h1 {
  color: #ffb4b4;
  font-size: 40px;
  text-decoration: underline;
  text-decoration-color: #000;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
  font-family: font2, font1;
}
.inservices {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 50px;
}
.inservices div {
  width: 350px;
  padding-top: 50px;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 200px;
}
.inservices div h1 {
  font-size: 25px;
}

.sec-2 button {
  border-radius: 50px;
  background: #ffb4b4;
  width: 200px;
  height: 45px;
  border: none;
  color: #fff;
  text-align: center;
  font-family: font2, font1;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: 0.8s;
}
.sec-2 button:hover {
  background: transparent;
  color: #ffb4b4;
  border-bottom: 2px #ffb4b4 solid;
  border-radius: 0;
}
.inservices div img {
  width: 200px;
  height: auto;
  border-radius: 15px;
  box-shadow: 1px 0 18px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 1160px) {
  .inservices {
    flex-direction: column;
    gap: 50px;
  }
  .inservices div {
    gap: 160px;
  }
}
