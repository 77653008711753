.sec-1S {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  gap: 50px;
  box-shadow: 0px -25px 20px -255px rgba(0, 0, 0, 0.45),
    25px 0px 20px -255px rgba(0, 0, 0, 0.45),
    0px 25px 20px -27px rgba(255, 180, 180, 1),
    -25px 0px 20px -255px rgba(0, 0, 0, 0.45);
}
.sec-1S h1 {
  color: #ffb4b4;
  font-size: 50px;
  font-family: font2, font1;
}
.sec-2S {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-self: center;
  gap: 50px;
  margin-top: 50px;
}
.sec-2S div {
  width: 350px;
  height: fit-content;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.sec-2S div h1 {
  color: #ffb4b4;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
