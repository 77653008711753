.sec-4 {
  width: 100%;
  height: fit-content;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  gap: 200px;
}
.block4 {
  width: 550px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.block4 h1 {
  color: #ffb4b4;
  font-size: 40px;
  text-decoration: underline;
  text-decoration-color: #000;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
  font-family: font2, font1;
}
.block4 p {
  font-family: font2, font1;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
}
.sec-4 .socialmedia {
  width: 100%;
  justify-content: flex-start;
}

.block5 {
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  background: #ffb4b4;
  border-radius: 0 30px 0 30px;
  padding: 30px;
  box-shadow: 1px 0 18px rgba(0, 0, 0, 0.2);
}
.block5 h1 {
  font-size: 48px;
  color: #fff;
}
.block5 p {
  font-size: 16px;
  font-family: font2, font1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.info {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}
.info input {
  width: 300px;
  height: 35px;
  text-align: end;
  padding-right: 10px;
  font-family: font2, font1;
  border-radius: 15px;
  border: none;
}
.block5 textarea {
  width: 300px;
  height: 100px;
  text-align: end;
  padding: 10px;
  font-family: font2, font1;
  border-radius: 15px;
  border: none;
}
.block5 button {
  border-radius: 50px;
  background: #ffb4b4;
  width: 200px;
  height: 40px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: font2, font1;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 2px #fff solid;
  outline: none;
  cursor: pointer;
  transition: 0.8s;
}
.block5 button:hover {
  background: #fff;
  color: #ffb4b4;
  border-radius: 15px;
}
form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
@media only screen and (max-width: 1000px) {
  .sec-4 {
    height: fit-content;
    flex-direction: column;
    gap: 50px;
  }
}
@media only screen and (max-width: 600px) {
  .block4 {
    width: 100%;
  }
  .block5 {
    width: 100%;
  }
}
@media only screen and (max-width: 460px) {
  .sec-4 {
    padding-left: 0;
    padding-right: 0;
  }
  .block4 {
    width: 90%;
    align-items: center;
  }
  .sec-4 .socialmedia {
    justify-content: center;
  }
  .block5 {
    width: 90%;
  }
  .block5 p {
    font-size: 16px;
  }
  .block6 {
    align-items: center;
  }
}
