.sec-3 {
  width: 100%;
  height: 700px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  gap: 250px;
}
.sec-3 img {
  width: 350px;
  height: auto;
  border-radius: 15px;
  box-shadow: 1px 0 18px rgba(0, 0, 0, 0.2);
}
.block3 {
  width: 600px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.block3 h1 {
  font-size: 40px;
  color: #ffb4b4;
  text-decoration: underline;
  text-decoration-color: #000;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
  font-family: font2, font1;
}
.block3 p {
  color: #000000;
  font-family: font2, font1;
  width: 100%;
  font-size: 20px;
  height: fit-content;
  text-overflow: clip;
  overflow-wrap: break-word;
  overflow: hidden;
  initial-letter-align: unset;
  vertical-align: super;
}
.socialmedia {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.sec-3 button {
  border-radius: 50px;
  background: #ffb4b4;
  width: 200px;
  height: 45px;
  flex-shrink: 0;
  border: none;
  color: #fff;
  align-self: center;
  text-align: center;
  font-family: font2, font1;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: 0.8s;
}
.sec-3 button:hover {
  background: transparent;
  color: #ffb4b4;
  border-bottom: 2px #ffb4b4 solid;
  border-radius: 0;
}

@media only screen and (max-width: 1350px) {
  .sec-3 {
    gap: 50px;
  }
}
@media only screen and (max-width: 1160px) {
  .sec-3 {
    flex-direction: column-reverse;
    height: fit-content;
  }
}
@media only screen and (max-width: 650px) {
  .block3 {
    width: 90%;
  }
  .sec-3 img {
    width: 90%;
  }
}
