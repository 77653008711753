.sec-5 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px #ffb4b4 solid;
  overflow: hidden;
  padding: 10px;
  gap: 150px;
}
.sec-5 a {
  font-family: font2, font1;
  text-decoration: none;
  color: #ffb4b4;
}
.sec-5 p {
  font-family: font2, font1;
  color: #000;
}
.sec-5 button {
  background: transparent;
  width: fit-content;
  height: fit-content;
  border: none;
  outline: none;
  cursor: pointer;
  color: #000;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.sec-5 img {
  width: 80px;
  height: auto;
  flex-shrink: 0;
}
@media only screen and (max-width: 460px) {
  .sec-5 {
    flex-direction: column;
    gap: 20px;
  }
}
