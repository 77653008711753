.sec-1 {
  width: 100%;
  height: 700px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 200px;
}
.block1 {
  width: 550px;
  height: 550px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.block1 h1 {
  width: 100%;
  color: #000;
  font-family: font2, font1;
  font-size: 95px;
  font-style: normal;
  font-weight: 400;
  line-height: 120.5%; /* 115.68px */
}
.block1 h1:nth-child(2) {
  font-size: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.block1 h1:nth-child(2) h1 {
  color: #ffb4b4;
  font-family: font2, font1;
  text-decoration: underline;
}
.block1 button {
  border-radius: 50px;
  background: #ffb4b4;
  width: 150px;
  height: 40px;
  flex-shrink: 0;
  border: none;
  color: #fff;
  text-align: center;
  font-family: font2, font1;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: 0.8s;
}
.block1 button:hover {
  background: transparent;
  color: #ffb4b4;
  border-bottom: 2px #ffb4b4 solid;
  border-radius: 0;
}

.block2 {
  width: 450px;
  height: 450px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 10px #ffb4b4 solid;
  border-radius: 15px;
}
.sec-1 .socialmedia {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.block2 img {
  height: 100%;
  width: auto;
  border-radius: 15px;
}

@media only screen and (max-width: 1350px) {
  .sec-1 {
    gap: 100px;
  }
}

@media only screen and (max-width: 1000px) {
  .sec-1 {
    height: fit-content;
    flex-direction: column;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 580px) {
  .block1 {
    width: 95%;
    height: fit-content;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .block2 {
    width: fit-content;
    height: 350px;
  }
}
@media only screen and (max-width: 460px) {
  .sec-1 {
    gap: 10px;
  }
  .block1 {
    padding-top: 50px;
    padding-bottom: 0;
  }
  .block1 h1 {
    font-size: 55px;
  }
  .block1 h1:nth-child(2) {
    font-size: 45px;
  }
  .block1 button {
    margin-top: 20px;
    align-self: center;
    width: 200px;
    height: 45px;
  }
  .block1 .pw {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .sec-1 .socialmedia {
    justify-content: center;
  }
}
