.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 450px;
  background: #ffb4b4;
  gap: 20px;
  border-radius: 15px;
  box-shadow: 1px 0 18px rgba(0, 0, 0, 0.2);
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.popup-inner .ht {
  position: absolute;
  top: 16px;
  font-size: 20px;
  color: #fff;
  font-family: font2, font1;
}
.images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.images h1 {
  color: #fff;
}
.popup-inner img {
  width: 70px;
  height: auto;
}

.popup-inner a {
  font-family: font2, font1;
  text-decoration: none;
  color: #fff;
  font-size: 15px;
}
.popup-inner p {
  font-family: font2, font1;
  color: #000;
  position: relative;
  font-size: 15px;
  top: 50px;
}

@media only screen and (max-width: 460px) {
  .popup-inner {
    width: 90%;
  }
}
