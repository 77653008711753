* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
header {
  display: flex;
  height: 70px;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  padding-left: 50px;
  box-shadow: 0px -25px 20px -255px rgba(0, 0, 0, 0.45),
    25px 0px 20px -255px rgba(0, 0, 0, 0.45),
    0px 25px 20px -27px rgba(255, 180, 180, 1),
    -25px 0px 20px -255px rgba(0, 0, 0, 0.45);
}
header img {
  display: flex;
  width: fit-content;
  height: 80%;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.navbar {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  z-index: 1;
  gap: 25px;
}
.navbar button {
  color: #ffb4b4;
  text-align: center;
  font-family: font2, font1;
  font-size: 24px;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 10px;
  background: transparent;
  outline: none;
  border: none;
  transition: 0.8s;
  flex-direction: column;
  justify-content: center;
  font-weight: 100;
  flex-shrink: 0;
}
.navbar button:hover {
  cursor: pointer;
  border-bottom: 2px #ffb4b4 solid;
  font-size: 25px;
}
.booknow {
  width: 150px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #ffb4b4;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}
.booknow:hover {
  border-radius: 15px;
  font-size: 25px;
}
header .navbtn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  color: #ffb4b4;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}
header .innav {
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
}
@font-face {
  font-family: font2;
  src: url("/src/Fonts/JosefinSans-SemiBold.ttf");
}
@font-face {
  font-family: font1;
  src: url("/src/Fonts/Rubik-SemiBold.ttf");
}
@media only screen and (max-width: 1000px) {
  header {
    padding-left: 0px;
  }
  header .navbtn {
    visibility: visible;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  header .innav {
    visibility: visible;
    flex-direction: column;
    width: fit-content;
    height: auto;
  }
  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    width: 100%;
    height: 100%;
    outline: solid 1px #ffffff44;
  }
  .booknow {
    background: transparent;
    color: #ffb4b4;
    width: 60%;
    pointer-events: none;
    cursor: not-allowed;
  }
  .navbar button {
    margin: 1rem;
  }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background: #fff;
    transition: 1s;
    transform: translateX(100%);
    overflow-y: scroll;
  }
  header .responsive_nav {
    transform: none;
  }
  nav .innav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  nav .innav img {
    margin-bottom: 50px;
    width: 100px;
    height: auto;
  }

  nav a {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 650px) {
  .space {
    width: 300px;
  }
}
@media only screen and (max-width: 460px) {
  .space {
    width: 180px;
  }
  header img {
    width: 56px;
  }
}
