.A1 {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  gap: 50px;
  box-shadow: 0px -25px 20px -255px rgba(0, 0, 0, 0.45),
    25px 0px 20px -255px rgba(0, 0, 0, 0.45),
    0px 25px 20px -27px rgba(255, 180, 180, 1),
    -25px 0px 20px -255px rgba(0, 0, 0, 0.45);
}
.A1 h1 {
  color: #ffb4b4;
  font-size: 50px;
  font-family: font2;
}

.Bookings {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 20px;
  flex-direction: column;
}
.order {
  border: 1px #ffb4b4 solid;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  height: fit-content;
  gap: 20px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 15px;
  box-shadow: 1px 0 18px rgba(0, 0, 0, 0.2);
}
.order button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.A2 {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.A2 button {
  border-radius: 50px;
  background: #ffb4b4;
  width: 200px;
  height: 45px;
  flex-shrink: 0;
  border: none;
  color: #fff;
  text-align: center;
  font-family: font2;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: 0.8s;
}
.A2 button:hover {
  background: transparent;
  color: #ffb4b4;
  border-bottom: 2px #ffb4b4 solid;
  border-radius: 0;
}
