.bsec-1 {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  gap: 50px;
  box-shadow: 0px -25px 20px -255px rgba(0, 0, 0, 0.45),
    25px 0px 20px -255px rgba(0, 0, 0, 0.45),
    0px 25px 20px -27px rgba(255, 180, 180, 1),
    -25px 0px 20px -255px rgba(0, 0, 0, 0.45);
}
.bsec-1 h1 {
  color: #ffb4b4;
  font-size: 50px;
  font-family: font2, font1;
}

.bsec-2 {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bsec-2 form {
  width: 60%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.bsec-2 input {
  width: 300px;
  height: 35px;
  text-align: end;
  padding-right: 10px;
  font-family: font2, font1;
  background: transparent;
  border-radius: 15px;
  border: 1px #ffb4b4 solid;
}
.bsec-2 select {
  width: 300px;
  height: 35px;
  text-align: end;
  padding-right: 10px;
  font-family: font2, font1;
  border-radius: 15px;
  border: 1px #ffb4b4 solid;
  background: transparent;
}
.bsec-2 button {
  border-radius: 50px;
  background: #ffb4b4;
  width: 200px;
  height: 40px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: font2, font1;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 2px #fff solid;
  outline: none;
  cursor: pointer;
  transition: 0.8s;
}
.bsec-2 button:hover {
  background: #fff;
  color: #ffb4b4;
  border-radius: 0;
  border-bottom: 1px #ffb4b4 solid;
}
.bsec-2 h1 {
  color: #ffb4b4;
  margin-top: 50px;
  font-size: 25px;
  font-family: font2, font1;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}
.terms {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.terms input {
  width: 25px;
}
.terms button {
  background: transparent;
  color: #ffb4b4;
  font-size: 20px;
  width: fit-content;
  text-decoration: underline;
  text-underline-offset: 5px;
  border: none;
}
.bsec-2 .paybtn {
  width: 250px;
  height: 35px;
  padding-bottom: 50px;
}
.terms button:hover {
  border: none;
}

@media only screen and (max-width: 460px) {
  .bsec-2 form {
    width: 100%;
  }
}
